import { useEffect, useState, createRef } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer, LabelList, ComposedChart } from "recharts";
import { RadialBarChart, RadialBar, Legend } from "recharts";
import { useScreenshot, createFileName } from "use-react-screenshot";
import Grid from "@mui/system/Unstable_Grid";
import TopSection from "../../../components/TopSection";
import Card from "../../../components/Card";
import { getCollections, getDate } from "../../../assets/js/functions";
import { descargarReporte } from "../../../assets/js/reportes";
import areas from "./areas.json";
import "./styles.scss";

export default function Reportes() {
	const [data, setdata] = useState(0);
	const [dimenciones, setDimenciones] = useState([]);
	const reference = createRef(null);
	const reference2 = createRef(null);
	const reference3 = createRef(null);
	const [image, takeScreenshot] = useScreenshot();
	const [image2, takeScreenshot2] = useScreenshot();
	const [image3, takeScreenshot3] = useScreenshot();
	// nps
	const [nps, setNps] = useState([]);
	const [npsPromedio, setNpsPromedio] = useState(0);
	// satisfaccion general
	const [satisfaccionGeneral, setSatisfaccionGeneral] = useState([]);
	const [satisfaccionGeneralPromedio, setSatisfaccionGeneralPromedio] = useState(0);
	const params = useParams();
	const { id } = params;

	useEffect(() => {
		getDatos();
	}, []);

	const download = (image, { name = `NPS-${areas[`${params.id}`].name}-${getDate()}`, extension = "png" } = {}) => {
		const a = document.createElement("a");
		a.href = image;
		a.download = createFileName(extension, name);
		a.click();
	};

	const download2 = (image2, { name = `SATISFACCION-${areas[`${params.id}`].name}-${getDate()}`, extension = "png" } = {}) => {
		const a = document.createElement("a");
		a.href = image2;
		a.download = createFileName(extension, name);
		a.click();
	};

	const download3 = (image2, { name = `DIMENCIONES-${areas[`${params.id}`].name}-${getDate()}`, extension = "png" } = {}) => {
		const a = document.createElement("a");
		a.href = image2;
		a.download = createFileName(extension, name);
		a.click();
	};

	const COLORS = ["#5499C7 ", "#EC7063", "#C39BD3", "#48C9B0", "#CD6155", "#F1948A", "#EB984E", "#FF8042", "#00C49F", "#F9E79F"];

	const getColor = (valor) => {
		let color = "";
		if (valor === 1 || valor === 2) {
			color = "#FF5733";
		}
		if (valor === 3 || valor === 4) {
			color = "#FFC300";
		}
		if (valor === 5) {
			color = "#58d68d";
		}
		return color;
	};

	const getImage = async () => {
		let a = reference.current;
		setTimeout(async () => {
			await takeScreenshot(a).then(download);
		}, 1000);
	};

	const getImage2 = () => {
		let a = reference2.current;
		setTimeout(async () => {
			await takeScreenshot2(a).then(download2);
		}, 1000);
	};

	const getImage3 = () => {
		let a = reference3.current;
		setTimeout(async () => {
			await takeScreenshot3(a).then(download3);
		}, 1000);
	};

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className='custom-tooltip'>
					<p className='label'>{`${label} : ${payload[0].value}`}</p>
					<p className='label'>{`Encuestas : ${payload[0].payload.encuestas}`}</p>
				</div>
			);
		}
		return null;
	};

	const getDatos = async () => {
		if (id !== "d23bca0d8813a46d07288313e2b65084") {
			const result = await getCollections(`${id}`);
			getNps(result);
			getDimenciones(result);
			getSatisfaccion(result);
			setdata(result.length);
		} else {
			let arrayGeneral = [];
			const result1 = await getCollections(`21232f297a57a5a743894a0e4a801fc3`);
			Object.values(result1).map((val) => {
				arrayGeneral.push(val);
			});
			const result2 = await getCollections(`b467cbda88db8941101d780ed8f19fe8`);
			Object.values(result2).map((val) => {
				arrayGeneral.push(val);
			});
			const result3 = await getCollections(`0ded17b2e1260363c5f39dff73244048`);
			Object.values(result3).map((val) => {
				arrayGeneral.push(val);
			});
			const result4 = await getCollections(`cd1727892c8a35d460d4dace151c36f1`);
			Object.values(result4).map((val) => {
				arrayGeneral.push(val);
			});
			const result5 = await getCollections(`d9df2de23ad53932be36331a9be625be`);
			Object.values(result5).map((val) => {
				arrayGeneral.push(val);
			});
			const result6 = await getCollections(`703fca8be78bae21d5b9fa6c0dd6b49e`);
			Object.values(result6).map((val) => {
				arrayGeneral.push(val);
			});
			const result7 = await getCollections(`8cb6ceae13ed574a8f8cbec2e8238ee2`);
			Object.values(result7).map((val) => {
				arrayGeneral.push(val);
			});
			const result8 = await getCollections(`527c128fd90c90859a5ca1617a2cd23d`);
			Object.values(result8).map((val) => {
				arrayGeneral.push(val);
			});
			const result9 = await getCollections(`a11b82bfe1571abb4e53be6b80be8714`);
			Object.values(result9).map((val) => {
				arrayGeneral.push(val);
			});
			getNps(arrayGeneral);
			getDimenciones(arrayGeneral);
			getSatisfaccion(arrayGeneral);
			setdata(arrayGeneral.length);
		}
	};

	const getNps = (array) => {
		let suma = 0,
			promedio = 0,
			sumaPromedio = 0,
			jsonResult = {},
			newArray = [];
		Object.values(array).map((val) => {
			suma += parseInt(val.pregunta28);
			promedio = suma / array.length;
			jsonResult = {
				name: `${areas[`${params.id}`].name}`,
				promedio: parseFloat(promedio.toFixed(2)),
				encuestas: array.length,
			};
		});
		newArray.push(jsonResult);
		setNps(newArray);
		// Object.values(newArray).map((val) => {
		// 	if (val.promedio && val.promedio > 0) {
		// 		sumaPromedio += val.promedio;
		// 	}
		// });

		// const promedioGeneral = sumaPromedio / array.length;
		// console.log(newArray);
		// console.log(suma);
		// let entero = promedioGeneral;
		// if (promedioGeneral < 10) {
		// 	entero = promedioGeneral.toFixed(2);
		// }
		setNpsPromedio(jsonResult.promedio);
	};

	const getSatisfaccion = (array) => {
		let jsonResult = {},
			newArray = [],
			suma = 0,
			promedio = 0;
		Object.values(array).map((val) => {
			suma +=
				parseInt(val.pregunta1) +
				parseInt(val.pregunta2) +
				parseInt(val.pregunta3) +
				parseInt(val.pregunta4) +
				parseInt(val.pregunta5) +
				parseInt(val.pregunta6) +
				parseInt(val.pregunta7) +
				parseInt(val.pregunta8) +
				parseInt(val.pregunta9) +
				parseInt(val.pregunta10) +
				parseInt(val.pregunta11) +
				parseInt(val.pregunta12) +
				parseInt(val.pregunta13) +
				parseInt(val.pregunta14) +
				parseInt(val.pregunta15) +
				parseInt(val.pregunta16) +
				parseInt(val.pregunta17) +
				parseInt(val.pregunta18) +
				parseInt(val.pregunta19) +
				parseInt(val.pregunta20) +
				parseInt(val.pregunta21) +
				parseInt(val.pregunta22) +
				parseInt(val.pregunta23) +
				parseInt(val.pregunta24) +
				parseInt(val.pregunta25) +
				parseInt(val.pregunta26) +
				parseInt(val.pregunta27);

			promedio = suma / array.length / 27;
			jsonResult = {
				name: `${areas[`${params.id}`].name}`,
				promedio: parseFloat(promedio.toFixed(2)),
				encuestas: array.length,
			};
		});
		newArray.push(jsonResult);
		setSatisfaccionGeneral(newArray);
		// Object.values(newArray).map((val) => {
		// 	if (val.promedio && val.promedio > 0) {
		// 		promedio += val.promedio;
		// 	}
		// });

		// const promedioGeneral = promedio / array.length;
		// let entero = promedioGeneral;
		// if (promedioGeneral < 5) {
		// 	entero = promedioGeneral.toFixed(2);
		// }
		setSatisfaccionGeneralPromedio(jsonResult.promedio);
	};

	const getDimenciones = (array) => {
		let satisfaccionLaboral = 0,
			liderazgo = 0,
			comunicacion = 0,
			relacionesInterpersonales = 0,
			reconocimientoRecompensas = 0,
			desarrolloCrecimientoProfesional = 0,
			condicionesDeTrabajo = 0,
			equilibrioVidaTrabajo = 0,
			autonomiaParticipacion = 0,
			culturaValoresOrganizacionales = 0,
			estabilidadSeguridadLaboral = 0,
			justiciaOrganizacional = 0,
			newArray = [];
		Object.values(array).map((val) => {
			if (val.pregunta1 && val.pregunta2) {
				satisfaccionLaboral += parseInt(val.pregunta1);
				satisfaccionLaboral += parseInt(val.pregunta2);
			}
			if (val.pregunta3 && val.pregunta4 && val.pregunta5) {
				liderazgo += parseInt(val.pregunta3);
				liderazgo += parseInt(val.pregunta4);
				liderazgo += parseInt(val.pregunta5);
			}
			if (val.pregunta6 && val.pregunta7 && val.pregunta8) {
				comunicacion += parseInt(val.pregunta3);
				comunicacion += parseInt(val.pregunta4);
				comunicacion += parseInt(val.pregunta5);
			}
			if (val.pregunta9 && val.pregunta10) {
				relacionesInterpersonales += parseInt(val.pregunta9);
				relacionesInterpersonales += parseInt(val.pregunta10);
			}
			if (val.pregunta11 && val.pregunta12) {
				reconocimientoRecompensas += parseInt(val.pregunta11);
				reconocimientoRecompensas += parseInt(val.pregunta12);
			}
			if (val.pregunta13 && val.pregunta14) {
				desarrolloCrecimientoProfesional += parseInt(val.pregunta13);
				desarrolloCrecimientoProfesional += parseInt(val.pregunta14);
			}
			if (val.pregunta15 && val.pregunta16) {
				condicionesDeTrabajo += parseInt(val.pregunta15);
				condicionesDeTrabajo += parseInt(val.pregunta16);
			}
			if (val.pregunta17 && val.pregunta18) {
				equilibrioVidaTrabajo += parseInt(val.pregunta17);
				equilibrioVidaTrabajo += parseInt(val.pregunta18);
			}
			if (val.pregunta19 && val.pregunta20) {
				autonomiaParticipacion += parseInt(val.pregunta19);
				autonomiaParticipacion += parseInt(val.pregunta20);
			}
			if (val.pregunta21 && val.pregunta22 && val.pregunta23) {
				culturaValoresOrganizacionales += parseInt(val.pregunta21);
				culturaValoresOrganizacionales += parseInt(val.pregunta22);
				culturaValoresOrganizacionales += parseInt(val.pregunta23);
			}
			if (val.pregunta24 && val.pregunta25) {
				estabilidadSeguridadLaboral += parseInt(val.pregunta24);
				estabilidadSeguridadLaboral += parseInt(val.pregunta25);
			}
			if (val.pregunta26 && val.pregunta27) {
				justiciaOrganizacional += parseInt(val.pregunta26);
				justiciaOrganizacional += parseInt(val.pregunta27);
			}
		});
		const promedio1 = satisfaccionLaboral / (array.length * 2);
		const promedio2 = liderazgo / (array.length * 3);
		const promedio3 = comunicacion / (array.length * 3);
		const promedio4 = relacionesInterpersonales / (array.length * 2);
		const promedio5 = reconocimientoRecompensas / (array.length * 3);
		const promedio6 = desarrolloCrecimientoProfesional / (array.length * 2);
		const promedio7 = condicionesDeTrabajo / (array.length * 2);
		const promedio8 = equilibrioVidaTrabajo / (array.length * 2);
		const promedio9 = autonomiaParticipacion / (array.length * 2);
		const promedio10 = culturaValoresOrganizacionales / (array.length * 3);
		const promedio11 = estabilidadSeguridadLaboral / (array.length * 2);
		const promedio12 = justiciaOrganizacional / (array.length * 2);
		newArray.push({
			name: "P1",
			promedio: parseInt(promedio1),
			encuestas: array.length,
		});
		newArray.push({
			name: "P2",
			promedio: parseInt(promedio2),
			encuestas: array.length,
		});
		newArray.push({
			name: "P3",
			promedio: parseInt(promedio3),
			encuestas: array.length,
		});
		newArray.push({
			name: "P4",
			promedio: parseInt(promedio4),
			encuestas: array.length,
		});
		newArray.push({
			name: "P5",
			promedio: parseInt(promedio5),
			encuestas: array.length,
		});
		newArray.push({
			name: "P6",
			promedio: parseInt(promedio6),
			encuestas: array.length,
		});
		newArray.push({
			name: "P7",
			promedio: parseInt(promedio7),
			encuestas: array.length,
		});
		newArray.push({
			name: "P8",
			promedio: parseInt(promedio8),
			encuestas: array.length,
		});
		newArray.push({
			name: "P9",
			promedio: parseInt(promedio9),
			encuestas: array.length,
		});
		newArray.push({
			name: "P10",
			promedio: parseInt(promedio10),
			encuestas: array.length,
		});
		newArray.push({
			name: "P11",
			promedio: parseInt(promedio11),
			encuestas: array.length,
		});
		newArray.push({
			name: "P12",
			promedio: parseInt(promedio12),
			encuestas: array.length,
		});
		setDimenciones(newArray);
	};

	return (
		<div className='content-area'>
			<TopSection title='Reportes' />
			<section id='reportes'>
				<Grid container direction='row' alignItems='center' spacing={2} style={{ marginBottom: "10px" }}>
					<Grid item xs={12} sm={10} md={10} lg={10}>
						<h3>Total de encuestas: {`${data > 0 ? data : 0}`}</h3>
					</Grid>
					<Grid item xs={12} sm={2} md={2} lg={2}>
						<div className='btn' onClick={() => descargarReporte(id, `${areas[`${params.id}`].name}`)}>
							Reporte
						</div>
					</Grid>
				</Grid>
				<Card name={`Reporte de ${areas[`${params.id}`].name}`}>
					<Grid container direction='row' alignItems='center' spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<div ref={reference} style={{ padding: "15px" }}>
								<Typography variant='h5' color='text' colorBrightness='secondary' style={{ marginBottom: "30px" }}>
									{`NPS ${npsPromedio > 0 ? npsPromedio : 0} / 10`}
								</Typography>
								<ResponsiveContainer width='100%' height={450}>
									<BarChart
										data={nps}
										margin={{
											top: 0,
											right: 0,
											left: 0,
											bottom: 0,
										}}
									>
										<CartesianGrid strokeDasharray='3 3' />
										<XAxis dataKey='name'></XAxis>
										<YAxis domain={[0, 5]} ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} />
										<Tooltip content={<CustomTooltip />} />
										<Bar dataKey='promedio' fill='#0088FE'>
											{nps.map((entry, index) => (
												<>
													<Cell style={{ cursor: "pointer" }} key={`cell-${index}`} fill={COLORS[(Math.random() * COLORS.length) | 0]} />
												</>
											))}
											<LabelList fill='#000' dataKey='promedio' position='centerTop' />
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</div>
							<div className='full center'>
								<div
									variant='info'
									className='btn'
									onClick={() => {
										getImage();
									}}
								>
									Descargar gráfica
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<div ref={reference2} style={{ padding: "15px" }}>
								<Typography variant='h5' color='text' colorBrightness='secondary' style={{ marginBottom: "30px" }}>
									{`SATISFACCIÓN GENERAL ${satisfaccionGeneralPromedio > 0 ? (satisfaccionGeneralPromedio > 5 ? 5 : satisfaccionGeneralPromedio) : 0} / 5`}
								</Typography>
								<ResponsiveContainer width='100%' height={450}>
									<BarChart data={satisfaccionGeneral}>
										<CartesianGrid strokeDasharray='3 3' />
										<XAxis dataKey='name'></XAxis>
										<YAxis domain={[0, 5]} ticks={[1, 2, 3, 4, 5]} />
										<Tooltip content={<CustomTooltip />} />
										<Bar dataKey='promedio' fill='#00C49F'>
											{satisfaccionGeneral.map((entry, index) => (
												<>
													<Cell style={{ cursor: "pointer" }} key={`cell-${index}`} fill={COLORS[(Math.random() * COLORS.length) | 0]} />
												</>
											))}
											<LabelList fill='#000' dataKey='promedio' position='centerTop' />
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</div>
							<div className='full center'>
								<div
									variant='info'
									className='btn'
									onClick={() => {
										getImage2();
									}}
								>
									Descargar gráfica
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div id='dimenciones' ref={reference3} style={{ padding: "15px" }}>
								<Typography variant='h5' color='text' colorBrightness='secondary' style={{ marginBottom: "30px" }}>
									{`DIMENSIONES `}
								</Typography>
								<ResponsiveContainer width='100%' height={350}>
									<BarChart data={dimenciones}>
										<CartesianGrid strokeDasharray='3 3' />
										<XAxis position='' dataKey='name'></XAxis>
										<YAxis domain={[0, 5]} ticks={[1, 2, 3, 4, 5]} />
										<Tooltip content={<CustomTooltip />} />
										<Bar dataKey='promedio' fill='#00C49F'>
											{dimenciones.map((entry, index) => {
												return (
													<>
														<Cell style={{ cursor: "pointer" }} key={`cell-${index}`} fill={getColor(entry.promedio)} />
													</>
												);
											})}
											<LabelList fill='#000' dataKey='promedio' position='centerTop' />
										</Bar>
									</BarChart>
								</ResponsiveContainer>
								<p>
									<label>P1 = Satisfacción Laboral</label>
								</p>
								<p>
									<label>P2 = Liderazgo</label>
								</p>
								<p>
									<label>P3 = Comunicación</label>
								</p>
								<p>
									<label>P4 = Relaciones Interpersonales</label>
								</p>
								<p>
									<label>P5 = Reconocimiento y Recompensas</label>
								</p>
								<p>
									<label>P6 = Desarrollo y Crecimiento Profesional</label>
								</p>
								<p>
									<label>P7 = Condiciones de Trabajo</label>
								</p>
								<p>
									<label>P8 = Equilibrio Vida-Trabajo</label>
								</p>
								<p>
									<label>P9 = Autonomía y Participación</label>
								</p>
								<p>
									<label>P10 = Cultura y Valores Organizacionales</label>
								</p>
								<p>
									<label>P11 = Estabilidad y Seguridad Laboral</label>
								</p>
								<p>
									<label>P12 = Justicia Organizacional</label>
								</p>
							</div>
							<div className='full center'>
								<div
									variant='info'
									className='btn'
									onClick={() => {
										getImage3();
									}}
								>
									Descargar gráfica
								</div>
							</div>
						</Grid>
					</Grid>
				</Card>
			</section>
		</div>
	);
}
