import Paper from '@mui/material/Paper';
import './styles.scss';

export default function Card({ children, name, clase = '' }) {
	return (
		<>
			<div className={clase}>
				<div className='card-header'>{name}</div>
				<Paper elevation={3} id='paper'>
					{children}
				</Paper>
			</div>
		</>
	);
}
