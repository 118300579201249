import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/system/Unstable_Grid";
import EditIcon from "@mui/icons-material/Edit";
import orderBy from "lodash.orderby";
import TopSection from "../../../components/TopSection";
import Card from "../../../components/Card";
import Table from "../../../components/Table";
import { getCollections } from "../../../assets/js/functions";
import "./styles.scss";

export default function Usuarios() {
	const [rowsTable, setRowsTable] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		getUSuarios();
	}, []);

	const getUSuarios = async () => {
		const data = await getCollections(process.env.REACT_APP_BD_USUARIOS);
		const ordernados = orderBy(data, ["nombre"], ["asc"]);
		setRowsTable(ordernados);
	};

	const columns = [
		{ field: "nombre", headerName: "NOMBRE", flex: 2 },
		{ field: "correo", headerName: "CORREO ELECTRÓNICO", flex: 2 },
		{
			field: "action",
			headerName: "OPCIONES",
			width: 200,
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						<div className='cursor' style={{ marginRight: "15px" }} onClick={() => navigate(`/admin/usuarios/actualizar/${params.id}`)}>
							<EditIcon />
						</div>
					</>
				);
			},
		},
	];

	return (
		<div className='content-area'>
			<TopSection title='Usuarios' />
			<section id='usuarios'>
				<Card name='Listado de usuarios'>
					<Grid container direction='row' alignItems='stretch' spacing={2}>
						<Grid item lg={2} md={2} sm={2} xs={12}>
							<div className='btn' onClick={() => navigate("/admin/usuarios/crear")}>
								Crear
							</div>
						</Grid>
						<Grid xs={12} sm={12} md={12} lg={12}>
							<Table rowsTable={rowsTable} columns={columns} />
						</Grid>
					</Grid>
				</Card>
			</section>
		</div>
	);
}
