import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineGridView, MdOutlineLogout, MdOutlinePeople } from "react-icons/md";
import { AiOutlineBarChart, AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { stringToArray } from "../../../assets/js/functions";

export default function SuperAdministrador() {
	const [open1, setOpen1] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (getPathname() === "reportes") {
			setOpen1(true);
		}
	}, []);

	const getPathname = () => {
		const pathname = window.location.pathname;
		const array = stringToArray(pathname, "/");
		return array[2];
	};
	const urlPath = getPathname();

	const logout = () => {
		sessionStorage.clear();
		navigate("/");
	};

	const goUrl = (url) => {
		setOpen1(false);
		window.open(url, "_self");
	};

	return (
		<div className='sidebar-body'>
			<div className='sidebar-menu'>
				<ul className='menu-list'>
					<li className='menu-item'>
						<div onClick={() => goUrl("/admin/dashboard")} className={`${urlPath === "dashboard" && "active"} menu-link`}>
							<span className='menu-link-icon'>
								<MdOutlineGridView size={18} />
							</span>
							<span className='menu-link-text'>Dashboard</span>
						</div>
					</li>
					<li className='menu-item'>
						<div onClick={() => goUrl("/admin/usuarios")} className={`${urlPath === "usuarios" ? "active" : ""} menu-link`}>
							<span className='menu-link-icon'>
								<MdOutlinePeople size={20} />
							</span>
							<span className='menu-link-text'>Usuarios</span>
						</div>
					</li>

					<li className='menu-item'>
						<div onClick={() => setOpen1(!open1)} className={` menu-link `}>
							<span className='menu-link-icon'>
								<AiOutlineBarChart size={20} />
							</span>
							<span className='menu-link-text'>
								Reportes <span className='icon-menu'>{open1 ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}</span>
							</span>
						</div>
					</li>
					{open1 && (
						<>
							<li className='menu-item'>
								<div onClick={() => window.open("/admin/reportes/21232f297a57a5a743894a0e4a801fc3", "_self")} className={`${window.location.pathname === "/admin/reportes/21232f297a57a5a743894a0e4a801fc3" && "active"} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: "30px" }}>
										Administrativos
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => window.open("/admin/reportes/b467cbda88db8941101d780ed8f19fe8", "_self")} className={`${window.location.pathname === "/admin/reportes/b467cbda88db8941101d780ed8f19fe8" && "active"} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: "30px" }}>
										Arriva
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => window.open("/admin/reportes/0ded17b2e1260363c5f39dff73244048", "_self")} className={`${window.location.pathname === "/admin/reportes/0ded17b2e1260363c5f39dff73244048" && "active"} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: "30px" }}>
										Comercial y Mercadeo
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => window.open("/admin/reportes/cd1727892c8a35d460d4dace151c36f1", "_self")} className={`${window.location.pathname === "/admin/reportes/cd1727892c8a35d460d4dace151c36f1" && "active"} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: "30px" }}>
										Consultoria
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => window.open("/admin/reportes/d9df2de23ad53932be36331a9be625be", "_self")} className={`${window.location.pathname === "/admin/reportes/d9df2de23ad53932be36331a9be625be" && "active"} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: "30px" }}>
										Diseño Gráfico
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => window.open("/admin/reportes/703fca8be78bae21d5b9fa6c0dd6b49e", "_self")} className={`${window.location.pathname === "/admin/reportes/703fca8be78bae21d5b9fa6c0dd6b49e" && "active"} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: "30px" }}>
										Diseño Instruccional
									</span>
								</div>
							</li>

							<li className='menu-item'>
								<div onClick={() => window.open("/admin/reportes/8cb6ceae13ed574a8f8cbec2e8238ee2", "_self")} className={`${window.location.pathname === "/admin/reportes/8cb6ceae13ed574a8f8cbec2e8238ee2" && "active"} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: "30px" }}>
										Heineken
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => window.open("/admin/reportes/527c128fd90c90859a5ca1617a2cd23d", "_self")} className={`${window.location.pathname === "/admin/reportes/527c128fd90c90859a5ca1617a2cd23d" && "active"} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: "30px" }}>
										Operaciones
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => window.open("/admin/reportes/a11b82bfe1571abb4e53be6b80be8714", "_self")} className={`${window.location.pathname === "/admin/reportes/a11b82bfe1571abb4e53be6b80be8714" && "active"} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: "30px" }}>
										Programación y Video
									</span>
								</div>
							</li>
							<li className='menu-item'>
								<div onClick={() => window.open("/admin/reportes/d23bca0d8813a46d07288313e2b65084", "_self")} className={`${window.location.pathname === "/admin/reportes/d23bca0d8813a46d07288313e2b65084" && "active"} menu-link `}>
									<span className='menu-link-text' style={{ marginLeft: "30px" }}>
										Spira México
									</span>
								</div>
							</li>
						</>
					)}
				</ul>
			</div>

			<div className='sidebar-menu sidebar-menu2'>
				<ul className='menu-list'>
					<li className='menu-item'>
						<div onClick={logout} className='menu-link'>
							<span className='menu-link-icon'>
								<MdOutlineLogout size={20} />
							</span>
							<span className='menu-link-text'>Logout</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
}
