import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LIGHT_THEME } from "../../constants/themeConstants";
import { ThemeContext } from "../../context/ThemeContext";
import { SidebarContext } from "../../context/SidebarContext";
import SuperAdministrador from "./SuperAdministrador";
import { MdOutlineClose } from "react-icons/md";
import LogoColor from "../../assets/images/Spira-Color.png";
import LogoBlanco from "../../assets/images/Spira-blanco.png";
import "./Sidebar.scss";

const Sidebar = () => {
	const { theme } = useContext(ThemeContext);
	const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);
	const navbarRef = useRef(null);
	const navigate = useNavigate();

	// closing the navbar when clicked outside the sidebar area
	const handleClickOutside = (event) => {
		if (navbarRef.current && !navbarRef.current.contains(event.target) && event.target.className !== "sidebar-oepn-btn") {
			closeSidebar();
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<nav className={`sidebar ${isSidebarOpen ? "sidebar-show" : ""}`} ref={navbarRef}>
			<div className='sidebar-top'>
				<div className='sidebar-brand'>
					<img className='cursor' onClick={() => navigate("/admin/dashboard")} style={{ width: "80%" }} src={theme === LIGHT_THEME ? LogoColor : LogoBlanco} alt='' />
				</div>
				<button className='sidebar-close-btn' onClick={closeSidebar}>
					<MdOutlineClose size={24} />
				</button>
			</div>
			<SuperAdministrador />
		</nav>
	);
};

export default Sidebar;
