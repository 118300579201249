import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import orderBy from "lodash.orderby";
import Swal from "sweetalert2";
import Grid from "@mui/system/Unstable_Grid";
import { Button } from "@mui/material";
import SectionTitle from "../../../components/SeccionTitle";
import { userActive } from "../../../assets/js/functions";
import datos from "./datos";
import www from "../../../assets/images/dashboard/www.png";
import "./styles.scss";

export default function Dashboard() {
	const navigate = useNavigate();
	const [data, setData] = useState([]);

	useEffect(() => {
		validarUsuario();
		orderDatos();
	}, []);

	const orderDatos = () => {
		const ordernados = orderBy(datos, ["titulo"], ["asc"]);
		setData(ordernados);
	};

	const validarUsuario = async () => {
		const data = await userActive();
		if (data.cambiarContraseña) {
			Swal.fire({
				title: "¡Espera!",
				html: `Tienes una contraseña temporal por favor cambiala por una mas segura`,
				icon: "warning",
				confirmButtonText: "Cambiar",
				confirmButtonColor: "#c1212a",
			}).then((result) => {
				if (result.isConfirmed) {
					navigate("/admin/configuracion");
				}
			});
		}
	};

	const copiarClipBoard = (titulo, url) => {
		navigator.clipboard.writeText(url);
		console.log(url);
		Swal.fire({
			icon: "success",
			title: `${titulo}`,
			html: `<p>Se copio el link</p>`,
			confirmButtonColor: "#e10613",
		});
	};

	return (
		<>
			<div className='content-area'>
				<SectionTitle title='Dashboard' />
				<section id='dashboard'>
					<Grid container direction='row' spacing={2}>
						{Object.values(data).map((val, id) => (
							<Grid item xs={12} sm={6} md={3} lg={3} id={id}>
								<div className='center container-reports'>
									<div className='title-module'>{val.titulo}</div>
									<div className='full center'>
										<img src={www} alt='icon' className='full' />
									</div>
									<Grid container direction='row' spacing={2}>
										<Grid item xs={12} sm={12} md={12} lg={12}>
											<Button className='btn' style={{ color: "#fff" }} onClick={() => copiarClipBoard(val.titulo, val.url)}>
												Copiar link
											</Button>
											<input id={`text5`} value='' />
										</Grid>
									</Grid>
								</div>
							</Grid>
						))}
					</Grid>
				</section>
			</div>
		</>
	);
}
