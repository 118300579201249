import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { TextField, Button } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import Swal from "sweetalert2";
import TopSection from "../../../../components/TopSection";
import Card from "../../../../components/Card";
import { setData, searchDataByValue } from "../../../../assets/js/functions";
import "./styles.scss";

export default function CrearUsurios() {
	const [campos, setCampos] = useState({});
	const auth = getAuth();

	const validar = async (e) => {
		e.preventDefault();
		const existCorreo = await searchDataByValue(process.env.REACT_APP_BD_USUARIOS, "correo", campos.correo);
		if (Object.keys(existCorreo).length > 0) {
			Swal.fire({
				title: "Precaución!",
				text: "Este correo electrónico ya se encuentra registrado",
				icon: "warning",
				confirmButtonText: "Cerrar",
				confirmButtonColor: "#c1212a",
			});
		} else {
			createUserWithEmailAndPassword(auth, campos.correo, process.env.REACT_APP_BD_PASSWORD_AUTENTICATION)
				.then(async (userCredential) => {
					console.log(userCredential);
					setData(process.env.REACT_APP_BD_USUARIOS, userCredential.user.uid, { ...campos, id: userCredential.user.uid }).then(() => {
						Swal.fire({
							title: "¡Registro exitoso!",
							html: `El usuario se creó exitosamente`,
							icon: "success",
							confirmButtonText: "Cerrar",
							confirmButtonColor: "#c1212a",
						}).then((result) => {
							if (result.isConfirmed) {
								window.location.reload();
							}
						});
					});
				})
				.catch((e) => {
					const errorCode = e.code;
					const errorMessage = e.message;
					console.log("Error: " + e);
					Swal.fire({
						title: "Oooops!",
						text: "Contacta un administrador",
						icon: "error",
						confirmButtonText: "Cerrar",
						confirmButtonColor: "#c1212a",
					});
				});
		}
	};

	return (
		<>
			<div className='content-area'>
				<TopSection title='Registrar usuarios' urlPadre={[["Usuarios", "/admin/usuarios"]]} />
				<section id='usuariosCrear'>
					<Card name='Registrar usuario'>
						<form onSubmit={validar}>
							<Grid container direction='row' spacing={2}>
								<Grid item lg={6} md={6} sm={12} xs={12}>
									<TextField required fullWidth id='nombre' label='Nombre' variant='outlined' onChange={(e) => setCampos({ ...campos, nombre: e.target.value })} />
								</Grid>
								<Grid item lg={6} md={6} sm={12} xs={12}>
									<TextField type='email' required fullWidth id='correo-electronico' label='Correo Electrónico' variant='outlined' onChange={(e) => setCampos({ ...campos, correo: e.target.value })} />
								</Grid>
								<Grid item lg={2} md={2} sm={2} xs={12}>
									<div className='full center'>
										<Button type='submit' variant='info' className='btn'>
											Registrar
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					</Card>
				</section>
			</div>
		</>
	);
}
