import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales";

export default function Table({ rowsTable, columns }) {
	return (
		<div style={{ height: `${rowsTable.length > 0 ? "auto" : "600px"}`, width: "100%" }}>
			<DataGrid
				rows={rowsTable}
				columns={columns}
				pageSize={10}
				initialState={{
					pagination: { paginationModel: { pageSize: 10 } },
				}}
				slots={{ toolbar: GridToolbar }}
				slotProps={{
					toolbar: {
						printOptions: { disableToolbarButton: true },
						csvOptions: { disableToolbarButton: true },
						showQuickFilter: true,
					},
				}}
				pageSizeOptions={[10, 20, 50, 100]}
				rowsPerPageOptions={[10]}
				localeText={esES.components.MuiDataGrid.defaultProps.localeText}
			/>
		</div>
	);
}
